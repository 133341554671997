<template>
  <div class="topicList">
    <div class="search-form">
      <el-form inline>
        <el-form-item label="话题名称">
          <el-input
            v-model="searchForm.topicName"
            size="mini"
            placeholder="请输入话题ID/名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="话题状态">
          <el-select v-model="searchForm.topicState" size="mini">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="search">查询</el-button>
          <el-button size="mini" icon="el-icon-refresh-left" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>

      <div>
        <el-button
          style="margin-top: 6px; margin-right: 10px"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="addOrEdit(1)"
          >新增</el-button
        >
        <!-- <el-dropdown size="mini">
          <el-button size="mini" icon="el-icon-sort"
            >排序<i class="el-icon-arrow-down el-icon--right"></i
          ></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              ><div @click="sort('createTime')">按时间</div></el-dropdown-item
            >
            <el-dropdown-item
              ><div @click="sort('heat')">按热度</div></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>

    <div class="category-table">
      <el-table border style="width: 100%" :data="tableData">
        <el-table-column width="60" type="index" label="序号"></el-table-column>
        <el-table-column
          width="120"
          label="话题ID"
          prop="subjectCode"
        ></el-table-column>
        <el-table-column label="话题封面" prop="cover">
          <template slot-scope="{ row }">
            <el-image fit="cover" :src="row.coverUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="话题名称" prop="subjectName"></el-table-column>
        <el-table-column
          width="120"
          label="话题分类"
          prop="categoryName"
        ></el-table-column>
        <el-table-column label="关联标签">
          <template slot-scope="{ row }">
            <div class="tags">
              <span
                v-for="item in row.subjectTagValueList"
                :key="item.id"
                :style="'background:' + item.colour"
                class="tag"
                >#{{ item.tagValue }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="88"
          label="动态数量"
          prop="topicNum"
        ></el-table-column>
        <el-table-column width="88" label="热度" prop="heat"></el-table-column>
        <el-table-column
          width="100"
          label="最后修改人"
          prop="updateUserName"
        ></el-table-column>
        <el-table-column
          width="160"
          label="最后修改时间"
          prop="updateTime"
        ></el-table-column>

        <el-table-column width="90" label="话题状态" prop="state">
          <template slot-scope="{ row }">
            <span v-if="row.isDisable == 0" style="color: #00a199">已启用</span>
            <span v-if="row.isDisable == 1" style="color: #9a9d9e">已禁用</span>
          </template>
        </el-table-column>
        <el-table-column width="220" label="操作">
          <template slot-scope="{ row }">
            <div style="color: #2861e2; cursor: pointer">
              <span
                v-if="row.isDisable == 0"
                style="
                  border-right: 1px solid #2861e2;
                  color: #e86247;
                  padding: 5px;
                "
                @click="disable(row)"
                >禁用</span
              >
              <span
                v-if="row.isDisable == 1"
                style="border-right: 1px solid; color: #2861e2; padding: 5px"
                @click="disable(row)"
                >启用</span
              >
              <span
                style="border-right: 1px solid; color: #2861e2; padding: 5px"
                @click="detail(row)"
                >详情</span
              >
              <span
                style="border-right: 1px solid; color: #2861e2; padding: 5px"
                @click="addOrEdit(0, row)"
                >编辑</span
              >
              <span v-if="row.isTop == 1" style="padding: 5px" @click="top(row)"
                >取消置顶</span
              >
              <span v-if="row.isTop == 0" style="padding: 5px" @click="top(row)"
                >置顶</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination-container">
      <el-pagination
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="currentPageSize"
        layout=" prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getTopicList,
  updateTopicIsDisable,
  updateTopicIsTop,
} from "@/api/friendCircle.js";

export default {
  data() {
    return {
      searchForm: {
        topicName: "",
        topicState: "",
      },
      options: [
        { value: 0, label: "已启用" },
        { value: 1, label: "已禁用" },
      ],
      tableData: [],
      currentPageSize: 10,
      currentPage: 1,
      total: 0,
      isEdit: false,
      // sortField: "1",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    addOrEdit(type, row) {
      // 新增
      if (type) {
        window.open(window.location.href + "/add");
      }
      // 编辑
      else {
        this.isEdit = true;
        window.open(window.location.href + "/add?id=" + `${row.subjectId}`);
      }
    },
    handleSizeChange(val) {},

    handleCurrentChange(val) {},

    getList() {
      const reqObj = {
        data: {
          searchKey: this.searchForm.topicName,
          isDisable: this.searchForm.topicState,
        },
        pageNumber: this.currentPage,
        pageSize: this.currentPageSize,
        sortField: this.sortField,
      };

      getTopicList(reqObj).then((res) => {
        if (res.success) {
          this.tableData = res.data.content;
          this.total = Number(res.data.total);
        }
      });
    },

    search() {
      this.currentPage = 1;
      this.getList();
    },

    reset() {
      Object.assign(this._data.searchForm, this.$options.data().searchForm);
      this.currentPage = 1;
      this.getList();
    },

    disable(row) {
      const reqObj = {
        data: {
          subjectId: row.subjectId,
          isDisable: row.isDisable ? 0 : 1,
        },
      };

      updateTopicIsDisable(reqObj)
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
            this.getList();
          }
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },

    top(row) {
      const reqObj = {
        data: {
          subjectId: row.subjectId,
          isTop: row.isTop ? 0 : 1,
        },
      };

      updateTopicIsTop(reqObj)
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功");
            this.getList();
          }
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },

    // sort(sortType) {
    //   this.sortField = sortType;
    //   this.getList();
    // },

    detail(row) {
      window.open(window.location.href + "/detail?id=" + `${row.subjectId}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.topicList {
  padding: 24px 20px;
  background: #ffffff;

  .search-form {
    ::v-deep .el-form-item {
      margin-right: 20px !important;
      margin-bottom: 24px;
    }

    display: flex;
    justify-content: space-between;
  }

  .category-table {
    .tags {
      display: flex;
      flex-wrap: wrap;

      .tag {
        border-radius: 4px;
        margin-right: 10px;
        margin-bottom: 5px;
        padding: 5px;
        white-space: nowrap;
      }
    }
  }
}
</style>
